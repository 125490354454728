.estado-activo {
    color: green;
  }
  
  .estado-inactivo {
    color: red;
  }



